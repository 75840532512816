.arrow{
    border: solid var(--border-color);
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(-45deg);
    &-up{
        transform: rotate(-135deg);
    }
    &-left{
        transform: rotate(135deg);
    }
    &-down{
        transform: rotate(45deg);
    }
    &-right{
        transform: rotate(-45deg);
    }

    //addClass
    //arrow__braedcrumb
    &__braedcrumb{
            margin: 0 9px 0 7px;
            top: -3px;
            border-color: var(--white-color);
            position: relative;
    }
}