@import '../../../styles/styles/mixin.scss';

.input{
    font-size: 16px;
    height: 30px;
    width: 100%;
    border-radius: 0px;
    padding: 10px 25px;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;

    select::-webkit-input-placeholder, .form-control::-webkit-input-placeholder, .form-select::-webkit-input-placeholder, textarea::-webkit-input-placeholder, input::-webkit-input-placeholder {
        color: var(--body-color);
        font-size: 16px;
    }
    -webkit-inner-spin-button{
        display: none;
    }
    input,textarea{
        width: 100%;
        height: 100%;
        background: transparent;
        color: var(--text-color);
        font-weight: 400;
        font-size: 16px;
        font-family: Oswald;
    }
    //input__track
    &__track{
        width: 300px;
        height: 70px;
        background-color: #ffffffce;
        margin-right: 50px;
        @include resize(mobile) {
            margin-right: 0;
            width: 100%;
            margin-bottom: 20px;
        }
    }
    //input__contact-form
    &__contact{
        &-form{
            color: var(--body-color);
            height: 62px;
            background-color: var(--smoke-color);
            //input__contact-form-textarea
            &-textarea{
                    color: var(--body-color);
                    height: 162px;
                    background-color: var(--smoke-color);
            }
        }
    }
    //input__calculate
    &__calculate{
        background-color: var(--smoke-color);
        min-height: 50px;
    }
}