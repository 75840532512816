@import '../../../styles/styles/mixin.scss';

.button{
    //button__about-us
    &__about-us {
        @include adaptiv-value(top, 30, 15, 1);
        &>button>div{
            color: var(--text-color-black);
        }
    }
    //button__container\
    &__container{
        padding: 5px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover{
            cursor: pointer;
        }
        &>button{
            display: flex;
            align-items: center;
            text-wrap: nowrap;
            position: relative;
            margin: 0 2px;
            z-index: 5;
        }
    }
    //button__yellow, button__transparent
    &__yellow, &__transparent{
        background-color: var(--theme-color);
        min-height: 58px;
        z-index: 2;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
        min-width: 170px;        
        flex-direction: row;
        text-transform: capitalize;
        text-align: center;
        color: var(--title-black);
        font-family: var(--para-font);
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        padding: 22px 30px;
        @include adaptiv-value(padding-top, 22, 7,1);
        @include adaptiv-value(padding-bottom, 22, 7,1);
        border-radius: 0;
        border: none;
        width: max-content;
        &:hover{
            &>button{
                padding: 0 0 0 20px;
                transition: all .8s;
                &>div:first-child{
                    position: absolute;
                    opacity: 1;
                    visibility: visible;
                    left: 0;
                    transition: all .8s, opacity .6s;
                }                
                &>div:last-child{
                    position: absolute;
                    opacity: 0;
                    visibility: hidden;
                    right: -30px;
                    transition: all .8s, opacity .6s;
                }      
            }
        }
        &>button{
            padding: 0 20px 0 0;
            position: relative;
            transition: all .8s;
            top: -2px;
            &>div {
                color:var(--text-color-black);
            }
            &>div:first-child{
                opacity: 0;
                visibility: hidden;
                left: -35px;
                position: absolute;
            }
            &>div:last-child{
                position: absolute;
                opacity: 1;
                visibility: visible;
                right: 0px;
                right: 0;
                transition: all .8s, opacity .6s;
            }     
            
        }

    }
    //\
    &__transparent{
        background-color: transparent;
        &>button{
            padding: 0 0 0 0px;
            left: 2px;
            top: 0;
            &>div{
                color: var(--text-color-white);
            }
            &>div:first-child{
                width: 35px;
                height: 35px;
                opacity: 1;
                visibility: visible;
                &>div>svg{
                    fill: var(--theme-color);
                }
            }
            &>div:last-child{
                opacity: 0;
                visibility: hidden;
                width: 35px;
                height: 35px;
                right: 0px;
                &>div>svg{
                    fill: var(--theme-color);
                }
            }     
            &>div>div{
                font-size: 22px;
                color: var(--theme-color);
            }
        }
        &:hover{
            &>button{
                left: -30px;
                padding: 0;

                &>span{
                    color: #fff !important;
                }            
                &>div:first-child{
                    opacity: 0;
                    visibility: hidden;
                    &>div>svg{
                        fill: #fff;
                    }
                }   
                &>div:last-child{
                    right: -37px;            
                    opacity: 1;
                    visibility: visible;
                    &>div>svg{
                        fill: #fff;
                    }
                }   
                &>div>div {
                    color: var(--text-color-black-white);
                }
            }
        }
    }
    
    &__slider{
        @include adaptiv-value(top, 50, 15,1);     
    }

    //button__transporent
    &__transporent{
        flex: 0 1 auto;
        &>div{
            color: var(--theme-color);
            top: 2px;
        }
    }    

    //button__high-quality-up
    &__high-quality-up{
        position: absolute;
        top: 1px;
        left: 50%;
        transform: translate(-50%, 0px);
        z-index: 10;
        &::after{
            content: '';
            width: 40px;
            height: 20px;
            border: 1px solid var(--border-color);
            left: -5px;
            top: 0;
            position: absolute;
            z-index: 11;
        }
        div[aria-hidden="false"]{
            background-color: aqua;
        }
    }
    //button__high-quality-down
    &__high-quality-down{
        position: absolute;
        bottom: 1px;
        left: 50%;
        transform: translate(-50%, 0px);
        &::after{
            content: '';
            width: 40px;
            height: 20px;
            border: 1px solid var(--border-color);
            left: -5px;
            top: 0;
            position: absolute;
            z-index: 11;
        }
    }
    //button__slider-creative-action
    &__slider-creative-action{
        background-color: var(--body-bg);
        border: 1px solid #3b3b3b;
        padding: 20px;
    }
    // button__goto-up
    &__goto-up{
        position: fixed;
        z-index: 999999;
        padding-right: var(--scroll-padding);
        right: calc(30px + var(--scroll-padding, 0px));
        bottom: 70px;
        height: 50px;
        width: 50px;
        cursor: pointer;
        display: block;
        border-radius: 50px;
        opacity: 1;
        visibility: visible;
        transform: translateY(45px);
        transition: opacity visibility 300ms linear;
        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            transform: translate(5px,-5px);
        }
        &::before {
            z-index: 4;
            border: solid 4px var(--theme-color);
            background-color: #ffffff;
          }
        &::after {
            z-index: 5;
            mix-blend-mode: soft-light;
            background-image: conic-gradient( #000 var(--fill-scrolling), transparent 0);
        }

    }
    //button__full-yellow
    &__full-yellow{
        width: 100%;
        &>button{
            &>div>div{
                color: var(--text-color-black);
                font-size: 20px;
            }
        }
    }
    //button__faq-close
    &__faq-close{
        visibility: hidden;    
        opacity: 0;
        transition: margin-left .5s, visibility 0s, opacity 0.5s ease-in-out;
        margin-left: 0;
        width: 0;
        //button__faq-close--active
        &--active{
            visibility: visible;
            opacity: 1;
            top: -5px;
            right: 0;
            transition: margin-left .5s, visibility 0s, opacity 0.5s ease-in-out;
            margin-left: 50%;
            position: relative;
            &::after{
                content: 'X';
                position: absolute;
                font-size: 20px;
                left: 75px;
                top: 0;
                width: 100%;
                height: 100%;
                &:hover{
                    background-color: red;
                }
            }
        }
        // &>button{
        //     margin-left: 10px;
        // }
    }
}
