@import '../../../../styles/styles/mixin.scss';
@import '../../../../styles/styles/keyframes.scss';

//addStyle
.block {
    //block__track-container-input
    &__track-container-input{
        justify-content: center;
        @include resize(mobile){
            flex-direction: column;
            // margin-bottom: 20px;
        }
    }
    //block__card-container
    &__card{
        &-container{
            min-width: 160px;
            min-height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-bottom: 20px;
            background-color: var(--card-bg);
            border-radius: 10px;
            padding: 20px;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
            transition: box-shadow 0.3s ease;        
            grid-column: span 2;
            @include resize(mobile) {
                    grid-column: auto;
                }
            &:hover {
                box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
            }
        //block__card-container--allotted
            &--allotted{
                min-width: 160px;
                min-height: 250px;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                margin-bottom: 20px;
                background-color: var(--card-bg);
                border-radius: 10px;
                padding: 20px;
                box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
                transition: box-shadow 0.3s ease;
            
                &:hover {
                    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
                }
                grid-column: span 3;   
                @include resize(mobile) {
                    // grid-template-columns: 1fr;
                    grid-column: auto;
                }
            }
        }
    }
    //block__container-header-section
    &__container-header-section {
        display: flex;
        flex-direction: row;
        position: relative;
        max-width: 1400px;
        width: 100%;
        justify-content: center;
        margin-bottom: var(--section-title-space);
        @include resize(big-landscape) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            @include adaptiv-value(margin-bottom, 50, 10, 1);
        }
        @include resize(mobile) {
            flex-wrap: wrap;
        }


    }

    //block__video-review-container-counter
    &__video-review-container-counter {
        // margin-top: -150px;
        background-color: var(--body-bg);
        padding: 90px 90px 0 90px;
        margin: -90px 90px 0 -90px;
        @include adaptiv-value(margin-right, 90, 0, 1);
        @include adaptiv-value(padding-right, 90, 0, 1);
        position: relative;
        z-index: 3;

        animation-name: var(--animation-main-counter);
        animation-duration: 4s;
        animation-delay: .1s;
        animation-fill-mode: both;
        bottom: -100%;
    }

    //block__container-video-review-counter
    &__container-video-review-counter {
        width: 100%;
        position: relative;
        align-items: center;
        margin-top: 24px;
        padding: 5px;
    }

    //block__container-high-quality
    &__container-high-quality {
        width: 100%;
        margin: 0 auto;
        position: relative;

        @include resize(big-landscape) {
            flex-direction: column;
        }
    }

    //block__container-high-quality-left
    &__container-high-quality-left {}

    //block__container-high-quality-right
    &__container-high-quality-right {
        max-width: 70%;
        flex-direction: column;
        justify-content: center;

        @include resize(big-landscape) {
            max-width: 100%;
        }
    }

    //block__high-quality-sidebar-container
    &__high-quality-sidebar-container {
        @include adaptiv-value(max-width, 450, 200, 1);

        margin-right: 30px;
        border: 1px solid var(--border-color);
        // padding: 65px 50px 65px 50px;
        @include adaptiv-value(padding-top, 65, 20, 1);
        @include adaptiv-value(padding-bottom, 65, 20, 1);
        @include adaptiv-value(padding-left, 50, 5, 1);
        @include adaptiv-value(padding-right, 50, 5, 1);

        position: relative;

        @include resize(desktop) {
            // padding: 35px 20px 35px 20px;

        }

        @include resize(big-landscape) {
            min-width: 100%;
            margin: 0 0 50px;
        }
    }

    //block__container-high-quality-list-items
    &__container-high-quality-list-items {
        margin-bottom: 22px;
        padding-bottom: 22px;
        border-bottom: 1px solid var(--border-color);
    }

    //block__container-high-quality-prevwiev-item
    &__container-high-quality-prevwiev-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        @include resize(big-landscape) {
            // max-width: 100%;
            flex-direction: column;
            align-items: center;
            margin-bottom: 50px;
        }
    }

    //block__wrap-high-quality-prevwiev-item-icons
    &__wrap-high-quality-prevwiev-item-icons {
        justify-content: space-between;
        padding-top: 15px;
    }

    //block__container-high-quality-prevwiev-image
    &__container-high-quality-prevwiev-image {
        // width: 350px;
        @include adaptiv-value(width, 350, 150, 1);
        height: 420px;
        align-items: center;

        &>div {
            position: absolute;
            left: -90px;
        }

        @include resize(big-landscape) {
            min-width: 100%;
            left: 0;
            top: 50px;
            overflow: hidden;

            &>div {
                position: relative;
                left: 0;
            }
        }
    }

    //block__wrap-high-quality-prevwiev-item
    &__wrap-high-quality-prevwiev-item {
        @include adaptiv-value(max-width, 570, 100, 1);
        padding: 10px 90px 10px 10px;
        position: relative;
        z-index: 3;
        border: 5px solid var(--theme-color);
        min-width: 0;

        @include resize(big-landscape) {
            padding: 10px;
            min-width: 50%;
        }

        @include resize(landscape) {
            min-width: 100%;
        }

    }

    // expert
    &__expert {

        //block__expert-container
        &-container {
            display: inline-block;
            text-align: center;
            position: relative;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            animation-delay: .2s;
            animation-duration: 5s;
            animation-name: var(--animation-main-expert);            
            

        }
        
        //block__expert-card-container
        &-card-container {
            position: relative;
            padding: 30px 30px 30px 30px;
            z-index: 2;
            min-height: 480px;
            @include adaptiv-value(min-height,480,400,1);
            // display: flex !important;
            // align-items: center;

            &::before {
                content: "";
                height: 100%;
                width: 100%;
                max-width: calc(100% - 20px);
                border: 1px solid var(--border-color-expert, --body-color);
                position: absolute;
                top: 0;
                left: 0;
                transition: 0.4s ease-in-out;
                pointer-events: none;
            }

            &:hover {
                cursor: pointer;

                &::before {
                    border: 1px solid var(--theme-color);
                    transition: all .8s;
                }
            }
            //block__expert-card-container-title
            &-title{
                flex-direction: column;
                // min-height: 139px;
                &>:first-child{
                    // min-height: 100px;
                }
                //block__expert-card-container-title-row                
                &-row{
                    flex-direction: row;
                    &>div{
                        &:first-child{
                            flex: 1 1 85%;
                        }
                        &:last-child{
                            flex: 1 1 15%;
                        }
                    }
                }
            }
        }

        //block__expert-card-media-container
        &-card-media-container {
            width: 100%;
            z-index: 1;
            position: relative;
            display: block;
            min-height: 40px;
            &>div {
                justify-content: flex-start;
                &::after {
                    content: '';
                    top: 0;
                    left: 11px;
                    z-index: -1;
                    background-color: var(--body-bg);
                    position: absolute;
                    width: 100%;
                    height: 100%;
                }

            }
        }

        //block__expert-card-image-container
        &-card-image-container {
            // width: 300px;
            position: relative;
            z-index: 2;
            width: calc(100% + 9px);
            min-height: 317px;
            max-height: 317px;
            &> div > img {
                // object-fit: contain !important;
                height: 100%;
            }
        }
    }

    &__contact {

        //block__contact-container-left
        &-container-left {
            // opacity: 0;
            position: relative;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            animation-delay: .2s;
            animation-duration: 3s;
            animation-name: var(--animation-main-contact-left);            
            @include resize(landscape){

            }
            &::after {
                content: '';
                position: absolute;
                inset: 0;
                border: 1px solid var(--border-color);
                transform: translate(-75px, 75px);
                z-index: -1;
            }
        }
        //block__contact-container-right
        &-container-right{
            padding: 15px;
           
            visibility: visible;
            // opacity: 0;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            animation-delay: .2s;
            animation-duration: 4s;
            animation-name: var(--animation-main-contact-right);
            position: relative;
            @include resize(desktop){
                margin-top: 150px;
            }
        }

        //block__contact-container-contact
        &-container-contact {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            background-image: -webkit-linear-gradient(357.99deg, rgba(22, 22, 22, 0.4) 0%, rgba(22, 22, 22, 0.4) 100%);
            background-image: linear-gradient(92.01deg, rgba(22, 22, 22, 0.4) 0%, rgba(22, 22, 22, 0.4) 100%);
            -webkit-backdrop-filter: blur(20px);
            backdrop-filter: blur(20px);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            &>div:not(:last-child){
                border-right: 1px solid #000;
            }
        }

        //block__contact-wrap-contact
        &-wrap-contact {
            padding: 25px 30px;
            @include resize(mid-desktop){
                @include adaptiv-value(padding-left,20,0,1);
                @include adaptiv-value(padding-right,20,0,1);                
            }
            @include resize(desktop){
                padding: 25px 30px;
            }
            align-items: center;
            justify-content: flex-start;
            gap: 10px;
        }

        
    }

    &__client{
        &-card{
            //block__client-card-container
            &-container{
                padding: 40px;
                margin-right: 40px;
                position: relative;
                z-index: 2;
                border: 1px solid var(--border-color);
                max-width: 90%;
                @include resize(landscape){
                    max-width: 100%;
                    @include adaptiv-value(padding, 40, 5,1);

                }
            }
           
            //block__client-card-self-info
            &-self-info{
                margin-left: 25px;                
            }
            //block__client-card-self-info-icon
            &-self-info-icon{
                height: 100px;
                &>div{
                    position: absolute;
                    right: 70px;
                    background-color: var(--body-bg);
                    padding: 7px;
                    @include resize(landscape){
                        right: 26px;
                        top: -25px;
                        @include adaptiv-value(right,60,65,1);
                       &>div>svg{
                        width: 80px;
                       } 
                    }
                }
            }
        }
    }

    //block__blogs-container-card
    &__blogs-container-card{
        border: 1px solid var(--border-color);
        @include adaptiv-value(max-width, 420, 150,1);
        @include resize(desktop){
            @include adaptiv-value(max-width, 420, 320,1);
        }
        min-height: 540px;
        @include resize(landscape){
            max-width: 100% !important;
        }
    }

    ///block__footer-copyright-container
    &__footer{
        &-copyright-container{
            width: 100vw;
            justify-content: center;
        }  
        //block__footer-container
        &-container{
            @include resize(big-landscape){
                flex-direction: column;
            }
            &>div{
                flex: 1 1 50%;
                &:last-child,&:first-child{
                    flex: 1 1 25%;

                }
            }
            //block__footer-container-inner-left
            &-inner-left{
                opacity: 0;
            animation-fill-mode: both;
            animation-iteration-count: 1;
            animation-delay: .2s;
            animation-duration: 1s;
            animation-name: fadeInUp;
            }
            //block__footer-container-inner-center
            &-inner-center{opacity: 0;
                animation-fill-mode: both;
                animation-iteration-count: 1;
                animation-delay: .2s;
                animation-duration: 1s;
                animation-name: fadeInUp;
                justify-content: center;
                @include resize(big-landscape){
                    justify-content: flex-start;
                }
            }
            //block__footer-container-inner-right
            &-inner-right{
                opacity: 0;
                animation-fill-mode: both;
                animation-iteration-count: 1;
                animation-delay: .2s;
                animation-duration: 1s;
                animation-name: fadeInUp;
            }
        }
        //block__footer-wrap-item-contact{}
        &-wrap-item-contact{
         align-items: center;   
         gap: 20px;

        }
    }
    //block__breadcrumbs-container-title
    &__breadcrumbs-container-title{
        position: absolute;
        z-index: 2;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        flex-direction: column;
        align-items: center;

    }

    &__about{
        //block__about-container-left
        &-container-left{
            // opacity: 0;
            // visibility: hidden;
            min-height: 600px;
            @include adaptiv-value(min-height,600, 350,1);

            animation-delay: .5s;
            animation-duration: .5s;
            animation-fill-mode: both;
            animation-name: var(--fadeInLeft);
            transition: all .5s;
            flex: 1 1 50%;
            flex-shrink: 0;
            width: 100%;
            max-width: 100%;
            padding-right: calc(var(--bs-gutter-x)/ 2);
            padding-left: calc(var(--bs-gutter-x)/ 2);
            position: relative;
            margin-right: 50px;
            @include resize(desktop){
                flex: 1 1 auto;
                margin-right: 0px;

            }
        }
        //block__about-container-right
        &-container-right{
            flex: 1 1 50%;
            // opacity: 0;
            // visibility: hidden;
            animation-delay: 0.5s;
            animation-duration: .5s;
            animation-fill-mode: forwards;
            animation-name: var(--fadeInRight);
            flex-shrink: 0;
            width: 100%;
            max-width: 100%;
            padding-right: calc(var(--bs-gutter-x)/ 2);
            padding-left: calc(var(--bs-gutter-x)/ 2);
            @include resize(desktop){
                flex: 1 1 auto;
            }
        }
        //block__about-container-image
        &-container-image{
            position: relative;
            width: 100%;
            height: 100%;
            // max-height: 565px;
            overflow: visible;
        }
        //block__about-image-1
        &-image-1{
            position: absolute;
            @include adaptiv-value(--x,70,10,1);
            @include adaptiv-value(--y,70,10,1);
            transform: translate(var(--x),var(--y));
            width: 70%;
            z-index: 2;
            @include resize(desktop){
                width: 90%;
            }
            &::after{
                @include adaptiv-value(--xa,-70,-10,1);
                @include adaptiv-value(--ya,-70,-10,1);
                position: absolute;
                transform: translate(var(--xa),var(--ya));
                content: '';
                // max-width: 500px;
                // min-width: 500px;
                width: 100%;
                height: 100%;
                border: 1px solid var(--border-color);
                z-index: -1;
            }
        }
        //block__about-image-2
        &-image-2{
            position: absolute;
            transform: translate(60px,60px);
            width: 50%;
            bottom: 0;
            right: 0;
            z-index: 2;
        }
    }
    //block__clientsay-container
    &__clientsay-container{
      position: absolute;
      background-color: var(--body-bg);
      display:block;
      z-index: 10;
      width: 45%;
      bottom: 0;
      right: 0;
      padding: 15px;
      @include resize(desktop){
        position: relative;
        width: 100%;
      }
      //block__clientsay-container-hide
      &-hide{
        right: -100%;
        transition: right .5s;
        position: absolute;
      background-color: var(--body-bg);
      display:block;
      z-index: 10;
      width: 45%;
      bottom: 0;
      padding: 15px;
      @include resize(desktop){
        right: 0;
        position: relative;
        width: 100%;
      }
      }
    }
    //block__header-top-container-phone
    &__header-top-container-phone{
        &>div>div>svg{
            path{
                fill: #fff;
            }
        }
        &:hover{
            cursor: pointer;
            &>span{
                color: var(--theme-color);
            }
            &>div>div>svg{
                path{
                    fill: var(--theme-color);
                }
            }
        }
    }
    &__delivery-info{
        &-container{
            //block__delivery-info-container-content
            &-content{
                padding: 0;
            }
        }
    }
    //block__delivery-price-card-conrainer-desc
    &__delivery-price-card-conrainer-desc{
        padding: 10px;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--border-color-expert, --body-color);
        box-shadow: 2px 2px 5px var(--border-color), -2px -2px 5px var(--border-color);
        background-color: var(--body-bg);
        position: relative;
        top: -45px;
        min-height: 175px;
        &>a{
            text-align: center;
            &:hover{
                &>h3{
                    // color: var(--theme-color);
                    -webkit-text-stroke: 0.75px var(--theme-color);
                    color: transparent;
                }
            }
        }
    }
    //block__repurchase-goods-infomap-item--right
    &__repurchase-goods-infomap-item--right{
        padding: 10px 70px;
        margin-left: 50%;
        border-left: 1px solid var(--theme-color);
        width: 50%;
        z-index: 4;
        flex-direction: column;
        color: var(--text-color-black);
        @include resize(big-landscape){
            border-left: none;
            padding: 10px 10px;
            border-right: 1px solid var(--theme-color);
            width: 96%;
            align-items: flex-start;
            margin: 0;
        }
        &::after {
            content: attr(data-list);
                position: absolute;
                left: -22px;
                top: 0;
                z-index: 5;
                font-size: 20px;
                background-color: var(--theme-color);
                width: 40px;
                height: 40px;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                @include resize(big-landscape){
                    left: auto;
                    right: -20px;
                }
            }
        }
    //block__repurchase-goods-infomap-item--left
    &__repurchase-goods-infomap-item--left{
            padding: 10px 70px;
            border-right: 1px solid var(--theme-color);
            width: 50.1%;
            align-items: flex-end;
            flex-direction: column;
            color: var(--text-color-black);
            z-index: 4;
            @include resize(big-landscape) {
                border-right: 1px solid var(--theme-color);
                width: 96%;
                margin: 0;
                align-items: flex-start;
                padding: 10px 10px;
            }
            &::after {
                content: attr(data-list);
                    position: absolute;
                    top: 0;
                    z-index: 5;
                    font-size: 20px;
                    background-color: var(--theme-color);
                    width: 40px;
                    height: 40px; 
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    right: -20px;
                    top: 0;
                }

    }
    &__faq{
        //block__faq-container
        &-container{
        // position: 'absolute', top: 70, right: 0, zIndex: 99999, width: '50%'
        position: absolute;
        top: 70px;
        right: 0;
        z-index: 99999;
        width: 50%;
        flex-direction: column;
        background-color: var(--smoke-color);
        border-radius: 10px;
        gap: 2px;
        @include resize(desktop){
            width: 100%;
            top: 70px;
            right: 0;
        }  
        }
        //block__faq-item-wrap
        &-item-wrap {
            padding: 50px;
            border-radius: 10px;
            border: 1px solid var(--border-color-expert, --body-color);
            box-shadow: 2px 2px 5px var(--border-color), -2px -2px 5px var(--border-color);
            background-color: var(--body-bg);
        }
        //block__faq-item-question
        &-item-question{
            justify-content: space-between;
        }
    }
    //block__order-tail-action-social
    &__order-tail-action-social{
        padding: 0;
        height: 0;
        visibility: hidden;
        opacity: 0;
        //block__order-tail-action-social--active
        transition: height .5s, visibility .2s, opacity .6s ease-in;
        &--active{
            padding: 0;
            height: 30px;
            visibility: visible;
            opacity: 1;
            transition: height .5s, visibility .2s, opacity .6s ease-in;
        }
    }
    //block__calculate-container
    &__calculate-container{
        gap: 50px;

        @include resize(landscape){
            flex-direction: column;
        }
    }
}