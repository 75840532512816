@import '../../../styles/styles/mixin.scss';

.icon{
    //icon__card
    &__card{
        width: 100% !important;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        path {
                fill-opacity: 1;
                stroke: var(--theme-color);
                fill: var(--border-color);
                stroke-width: 1;
                stroke-dasharray: 2500;
                stroke-dashoffset: 2500;
            }
        
            &>div>svg:hover {
                cursor: pointer;
        
                &>path {
                    animation: fillAni 5s ease-in-out;
                }
            }
    }
    //icon__svg
    &__svg{
        width: 20px;
    }
    //icon__button
    &__button{
        background-color: transparent;
        width: 20px;
        height: 20px;
    }
    padding: 0;    
    //icon__image
    &__image{
        padding: 0;
    }
    
                                        //addClass
    //icon__inrtroduction
    &__inrtroduction{  
        path {
            fill-opacity: 1;
            stroke: var(--theme-color);
            fill: var(--border-color);
            stroke-width: 1;
            stroke-dasharray: 2500;
            stroke-dashoffset: 2500;
        }
        &>div>svg:hover{
            cursor: pointer;
            & > path {
                animation: fillAni 5s ease-in-out;
            }
        }
    }
    //icon__link-header
    &__link-header{
        @include adaptiv-value(width, 20,15,1);
        // path {
        //     fill-opacity: 1;
        //     stroke: var(--theme-color);
        //     fill: var(--border-color);
        //     stroke-width: 1;
        //     stroke-dasharray: 2500;
        //     stroke-dashoffset: 2500;
        // }
        // &>div>svg:hover{
        //     cursor: pointer;
        //     & > path {
        //         animation: fillAni 5s ease-in-out;
        //     }
        // }
    }
    @keyframes fillAni {
        0%{
            
    stroke-dashoffset: 2500;
    fill: transparent;

        }
        100%{
            stroke-dashoffset: 0;
    stroke-width: 1;
    fill: var(--border-color);            
        }
        
    }
}