@import '../../../styles/styles/mixin.scss';

.text {
  padding: 0;
  display: flex;
  font-size: 14px;
  font-family: var(--body-font);
  color: var(--body-color);
  overflow: hidden;
  text-align: start;
  text-wrap: wrap;

  &>div {
    width: 100%,
  }

  //text__social-media-title
  &__social-media-title {
    display: inline-block;
    position: relative;
    @include adaptiv-font(16, 12);
    color: var(--white-color);
    letter-spacing: 0.06em;
    text-transform: uppercase;
    @include adaptiv-value(margin-right, 20, 0, 1);

    &:hover {
      cursor: pointer;
      color: var(--theme-color);
    }
  }

  //text__header-top-phone
  &__header-top-phone {
    display: inline-block;
    position: relative;
    @include adaptiv-font(16, 12);
    color: var(--white-color);
    letter-spacing: 0.06em;
    text-transform: uppercase;
    @include adaptiv-value(margin-left, 8, 5, 1);
    // margin-right: 20px;
  }

  //text__sub-title
  &__sub-title {
    color: var(--theme-color);
    font-size: 14px;
    margin-top: 20px;
    margin-bottom: 8px;
    text-align: left;
  }

  // text__title-sub
  &__title-sub {
    font-size: 14px !important;
    font-family: var(--para-font) !important;
    color: var(--theme-color) !important;
    display: block !important;
    margin-top: -0.3em !important;
    margin-bottom: 24px !important;
    font-weight: 400 !important;
  }

  // text__title
  &__title {
    padding: 0;
    text-transform: uppercase;
    display: inline-flex;
    font-weight: 700;

    //text__title--center
    &--center {
      justify-content: center;
    }
  }

  //addClass
  //text__header-section--full
  &__header-section--full {
    text-transform: uppercase;
    line-height: 180%;
    font-weight: 600;
    margin-right: 10px;

    @include resize(mobile) {
      flex-direction: column;
    }
  }

  //text__theme-title
  &__theme-title {
    color: var(--theme-color) !important;
    text-transform: uppercase;
    font-weight: 600;
    margin-left: 7px;
  }

  //text__stroke-title
  &__stroke-title {
    // padding-left: 15px;
    -webkit-text-stroke: 0.75px var(--theme-color);
    // -webkit-text-stroke: 0.75px var(--border-color);
    text-transform: uppercase;
    font-weight: 600;
    align-items: center;
    line-height: 140%;
    @include adaptiv-font(48, 32);
    color: transparent;
    // @include resize(desktop) {
    //   padding: 0;
    // }
  }

  //text__feature-card-title
  &__feature-card-title {
    padding-right: 5px;

    &>a {
      color: #fff;

    }
  }

  //text__feature-text-location
  &__feature-text-location {
    color: var(--theme-color);
  }

  //text__video-review-count-desc
  &__video-review-count {
    @include adaptiv-font(64, 30);
    font-weight: 600;
    color: var(--theme-color);
    margin-bottom: 35px;
  }

  //text__video-review-count-desc
  &__video-review-count-desc {
    font-size: 12px;
    color: var(--white-color);
    font-weight: 400;
    display: block;
    margin-bottom: -0.55em;
  }

  //text__title-item-high-quality-sidebar
  &__title-item-high-quality-sidebar {
    font-weight: 100;
    text-transform: capitalize;
  }

  //text__title-introduction
  &__title-introduction {
    font-size: 30px;
    font-weight: 300;
    text-transform: capitalize;
    margin-bottom: 25px;
    min-height: 80px;
  }

  //text__text-introduction
  &__text-introduction {
    margin-bottom: -0.5em;
  }

  // expert
  &__expert {

    //text__expert-title
    &-title {
      margin-bottom: 15px;
      // min-height: 100px;
      text-align: left;
      @include adaptiv-font(30, 15);
    }

    //text__expert-sub-title
    &-sub-title {
      min-height: 50px;
      @include adaptiv-font(16, 10);

    }
  }

  &__blogs-main {

    //text__blogs-main-title
    &-title {
      width: 100%;

      &>a {
        color: var(--white-color);
      }

      &>a:hover {
        cursor: pointer;
        color: var(--theme-color);
      }

    }

    //text__blogs-main-desc
    &-desc {
      width: 100%;
      text-align: start;
      overflow: hidden;
      min-height: 50px;
    }
  }

  //text__footer-copyright
  &__footer {
    &-copyright {
      &>a {
        color: var(--theme-color)
      }
    }

    //text__footer-sotial-media
    &-sotial-media {
      color: var(--theme-color);
    }

    //text__footer-desc
    &-desc {
      padding: 0px;
    }

    //text__footer-title
    &-title {
      padding: 0;
      font-family: var(--title-font);
      font-size: 30px;
      font-weight: 400;
      color: var(--white-color);
      padding: 0 0 15px 0;
      margin: -0.14em 0 40px 0;
      position: relative;

      &::after {
        position: absolute;
        width: 60px;
        height: 2px;
        background-color: var(--theme-color);
        content: '';
        bottom: 0;
      }
    }

    //text__footer-desc-address
    &-desc-address {
      line-height: 1;
      display: block;
      color: var(--white-color);
      line-height: 140%;
      text-transform: lowercase;
      @include adaptiv-font(20, 10);
      font-family: var(--title-font);
      margin-bottom: 0;
    }

  }

  //text__breadcrumbs-title
  &__breadcrumbs-title {
    color: var(--white-color);
    margin: -0.22em 0 -0.12em 0;
    font-size: 60px;
    line-height: 1.1666;
    font-weight: 700;

  }

  //text__breadcrumbs-links
  &__breadcrumbs-links {
    &>a {

      white-space: normal;
      color: inherit;
      word-break: break-word;
      font-weight: 400;
      font-size: 20px;
      color: var(--white-color);
    }
  }

  //text__big-title
  &__big-title {
    font-size: 200px;
    font-weight: 600;
    display: inline-block;
    line-height: 0.93;
    margin-top: -0.099em;
    -webkit-text-stroke: 1px var(--border-color);
    color: transparent;
  }

  //text__client-card-title-self-info-title
  &__client-card-title-self-info-title {
    @include adaptiv-font(20, 10);
  }

  //text__card-title
  &__card {
    &-title {
      color: var(--white-color);
      font-size: 22px;
    }
  }

  //text__main-slider-label-track
  &__main-slider-label-track {
    margin-top: 40px;
    color: var(--text-color-white);
    font-size: 40px;
  }

  //text__faq-title
  &__faq-title {
    transition: color .3s;
    font-size: 20px;

    &:hover {
      cursor: pointer;
      color: var(--theme-color) !important;
      transition: color .3s;
    }
  }

  //text__delivery-info-about-cargo-title-card
  &__delivery-info-about-cargo-title-card {
    min-height: 50px;

    &>div>p {
      font-weight: 600;
      text-align: center;
    }
  }

  //text__calculate-price
  &__calculate-price {
    font-size: 30px;
    font-weight: 600;
    color: var(--theme-color);
    margin: 5px;
    line-height: 120%;
    width: min-content;
  }

}

//style-table-card
.style-table-card {
  &>div>figure>table>tbody {
    padding: 0;
  }
}