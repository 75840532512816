@import '../../../styles/styles/mixin.scss';

.image{
    //image__picture
    &__picture{
        padding: 0;
        >span{
            display: block !important;
        }
    }

    // addClass
    //image__background
    &__background{
        position: absolute;
        z-index: -1;
        top: -250px;
        width: 50%;
        right: 0;
        animation-fill-mode: forwards;
        animation: var(--jumpAni) 7s linear infinite;
        @include resize(desktop){
            width: 100%;
            top: -200px;
        }
    }
    //image__about-main
    &__about-main{
        position: relative;
        @include adaptiv-value(left, 70, 25,1);
        @include adaptiv-value(top, 70, 25,1);
        z-index: 1;
        width: 90%;
        &::after{
            content: '';
            width: 100%;
            height: 125%;
            border: 1px solid var(--border-color);
            position: absolute;
            @include adaptiv-value(left, -70, -25,1);
            @include adaptiv-value(top, -70, -25,1);
            z-index: -1;
            @include resize(desktop){
                height: 113%;                
            }
        }
    }
    //image__client-avatar
    &__client-avatar{
        border-radius: 50%;
        overflow: hidden;
        margin: 0;
        max-width: 120px;
        @include resize(landscape){
            @include adaptiv-value(max-width, 120, 80,1);
        }
    }
    //image__feature-card-image
    &__feature-card-image{
        min-height: 410px;
        max-height: 410px;
        &>img{
            height: 400px;
        }
    }
}